<template>
  <div>
    <membershipFilter
      v-if="$can('filter', 'memberships')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage" />
    <b-card>
      <TitleTable titleTable="membership parameters" iconTable="fas fa-disease" />
      <div class="m-1">
        <membershipEdit
          v-if="isEditmembershipSidebarActive"
          :isEditReviewSidebarActive.sync="isEditmembershipSidebarActive"
          :itemEdit="itemEdit"
          @editmembershipSuccess="editmembershipSuccess" />
        <membershipAdd
          :isAddNewmembershipSidebarActive.sync="isAddNewmembershipSidebarActive"
          @createmembership="createmembership" />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <treeselect
              v-model="perPage"
              :options="perPageOptions"
              :clearable="true"
              class="w-25" />
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <!-- <GoBack class="mr-2" /> -->
              <b-button
                v-if="$can('store', 'memberships')"
                variant="primary"
                @click="isAddNewmembershipSidebarActive = true"
                :disabled="loading">
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t('Add') }} membership parameter</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found">
        <template #cell(name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
          </div>
        </template>
        <template #cell(price)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">$ {{ data.item.price }}</span>
          </div>
        </template>
        <template #cell(validity)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.validity }}</span>
          </div>
        </template>
        <template #cell(facilities)="data">
          <ul class="list-unstyled my-2 facility">
            <li v-for="(facility, i) in data.item.facilities" :key="i">
              <b-badge variant="primary">
                <span class="text-white">
                  {{ facilityOptions.find(fac => fac.id == facility).name }}
                </span>
              </b-badge>
            </li>
          </ul>
        </template>
        <template #cell(parameters_details)="data">
          <ul class="list-unstyled my-2 facility">
            <li v-for="(parameter, i) in data.item.parameters_details" :key="i">
              <b-badge variant="primary">
                <span class="text-white">
                  {{
                    parameter.parametrizable
                      ? `${parameter.parametrizable.name} - ${parameter.count} - ${parameter.activated_at}`
                      : `${parameter.memberships_parameters_types.name} - ${parameter.count} -
                                                                        ${parameter.activated_at}`
                  }}
                </span>
              </b-badge>
            </li>
          </ul>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.status ? $t('Active') : $t('Inactive') }}
            </span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="$can('update', 'memberships')"
            variant="outline-primary"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Lots'"
            @click="goMembership(data.item)">
            <i class="fas fa-poll-h icon-nm"></i>
          </b-button>
          
          <b-button
            v-if="$can('update', 'memberships')"
            variant="outline-primary"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Edit'"
            @click="editmembership(data.item)">
            <i class="fas fa-pen icon-nm"></i>
          </b-button>

          <b-button
            v-if="$can('destroy', 'memberships')"
            variant="outline-danger"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Delete'"
            @click="deletemembership(data.item)">
            <i class="fas fa-trash-alt icon-nm"></i>
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import axiosAC from '@/core/services/api/admin/consults/memberships'

import ToastNotification from '@/components/ToastNotification'
import SpinnerLoading from '@/components/SpinnerLoading'
import PaginationTable from '@/components/PaginationTable'
import TitleTable from '@/components/TitleTable'
import GoBack from '@/components/ButtonBack'
import Swal from 'sweetalert2'

import membershipEdit from './membershipEdit'
import membershipAdd from './membershipAdd'
import membershipFilter from './membershipFilter'
import { ref } from '@vue/composition-api'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    membershipEdit,
    membershipAdd,
    membershipFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'id',
        { key: 'name', label: 'Name', sortable: true },
        { key: 'price', sortable: true },
        { key: 'validity', sortable: true },
        { key: 'facilities', sortable: true },
        {
          key: 'parameters_details',
          label: 'Parameters Details (item - count - activated_at)',
          sortable: true,
        },
        { key: 'status', label: 'Status' },
        // A virtual column made up from two fields
        { key: 'actions', label: 'Actions' },
      ],
      items: null,
      loading: false,
      isEditmembershipSidebarActive: false,
      isAddNewmembershipSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100].map(i => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      membershipFilter: null,
      facilityOptions: JSON.parse(atob(localStorage.getItem('setFacilityOptions'))),
    }
  },
  mounted() {
    this.getmembership()
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getmembership()
      } else {
        this.searchFilter(this.membershipFilter)
      }
    },
  },
  methods: {
    getmembership() {
      axiosAC
        .membershipParameterList(this.perPage)
        .then(({ data: { data, current_page, total, per_page, ...res } }) => {
          this.items = data
          this.totalUsers = total
          this.dataMeta.of = total
          this.dataMeta.from = res.from
          this.dataMeta.to = res.to
          this.currentPage.page = current_page
        })
    },
    editmembership(item) {
      this.isEditmembershipSidebarActive = true
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)))
    },
    goMembership(data) {
      this.$router.push({
        name: 'admin-appointment-membership-parameters-lots',
        params: { dataGoMembershipLots: data },
      })
    },
    editmembershipSuccess(value) {
      this.isEditmembershipSidebarActive = false
      this.pageChanged(this.currentPage.page)
    },
    createmembership(status) {
      this.isAddNewmembershipSidebarActive = false
      this.pageChanged(this.currentPage.page)
    },
    deletemembership(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axiosAC.membershipParameterDelete(item.id).then(response => {
            this.$refs.toast.success('membership type has been deleted successfully')
            this.pageChanged(this.currentPage.page)
          })
        }
      })
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosAC
          .membershipParameterPagination(this.perPage, page)
          .then(({ data: { data, total, current_page, ...res } }) => {
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = res.from
            this.dataMeta.to = res.to
            this.currentPage.page = current_page
          })
      } else {
        axiosAC
          .membershipParameterFilterPagination(this.perPage, page, this.membershipFilter)
          .then(({ data: { data, total, current_page, ...res } }) => {
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = res.from
            this.dataMeta.to = res.to
            this.currentPage.page = current_page
          })
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true
        this.membershipFilter = value
        axiosAC
          .membershipParameterFilter(this.perPage, value)
          .then(({ data: { data, total, current_page, ...res } }) => {
            this.loading = false
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = res.from
            this.dataMeta.to = res.to
            this.currentPage.page = current_page
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        this.getmembership()
      }
    },
    filterPage(value) {
      this.filtro = value
    },
  },
}
</script>

<style></style>
